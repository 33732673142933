import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"800","scrollable":""},on:{"input":_vm.onDialogChangeState},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_vm._t("activator",null,null,{ attrs: attrs, on: on })]}}],null,true),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VForm,{key:_vm.showDialog,on:{"submit":function($event){$event.preventDefault();return _vm.emitTier.apply(null, arguments)}}},[_c(VCard,[_c(VCardTitle,{staticClass:"d-flex justify-space-between align-center"},[(_vm.value)?_c('span',[_vm._v(_vm._s(_vm.$t('Update tier')))]):_c('span',[_vm._v(_vm._s(_vm.$t('Add new tier')))]),_c(VBtn,{attrs:{"icon":"","small":"","depressed":""},on:{"click":function($event){_vm.showDialog = false}}},[_c(VIcon,[_vm._v("close")])],1)],1),_c(VDivider),_c(VCardText,[_c('div',{staticClass:"d-flex flex-column my-4 flex-gap-32"},[_c('product-tier-inputs',{staticClass:"d-flex flex-column flex-gap-16",attrs:{"errors":_vm.errors,"product":_vm.product},on:{"input":_vm.onItemChange},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}})],1)]),_c(VDivider),_c(VCardActions,{staticClass:"d-flex justify-end pa-4"},[_c(VBtn,{attrs:{"disabled":!_vm.isValid,"depressed":"","color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Save tier'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }