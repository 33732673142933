import { render, staticRenderFns } from "./ProductDefaultVariant.vue?vue&type=template&id=ec2b9f6e&"
import script from "./ProductDefaultVariant.vue?vue&type=script&lang=ts&"
export * from "./ProductDefaultVariant.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports