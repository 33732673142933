import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',[_vm._v(_vm._s(_vm.$t('Extras')))]),_c(VBtn,{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.addExtras}},[_vm._v(" "+_vm._s(_vm.$t('Add extras'))+" ")])],1),((_vm.val.extras || []).length)?_c('translation-tabs',{staticClass:"px-0 mx-0 mt-4",attrs:{"translatable-values":['extras']},scopedSlots:_vm._u([{key:"default.extras",fn:function(ref){
var code = ref.code;
return _vm._l((_vm.val.extras),function(extras,index){return _c('div',{key:("default-" + (extras.key))},[_c('expandable-content',{attrs:{"eager":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('product-extra-expansion-tile',{attrs:{"extras":extras}})]},proxy:true},{key:"after-icon",fn:function(){return [_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteExtras(index)}}},[_c(VIcon,[_vm._v("delete")])],1)]},proxy:true},{key:"content",fn:function(){return [_c('product-extras-input',{key:("extras-" + index),staticClass:"mx-4 my-4",attrs:{"value":extras,"breadcrumb-error-code":("extras-" + code + "-" + index)},on:{"input":function($event){return _vm.updateExtras($event, index)}}})]},proxy:true}],null,true)})],1)})}},{key:"extras",fn:function(ref){
var code = ref.code;
return _vm._l((_vm.translations(code).get()),function(extras,index){return _c('div',{key:((extras.key) + "-" + code)},[_c('expandable-content',{attrs:{"eager":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('product-extra-expansion-tile',{attrs:{"extras":extras}})]},proxy:true},{key:"content",fn:function(){return [_c('product-extras-input',{key:("extras-" + index),staticClass:"mx-4 my-4",attrs:{"value":extras,"translated":"","source-options":_vm.val.extras[index].options,"breadcrumb-error-code":("extras-" + code + "-" + index)},on:{"input":function($event){return _vm.updateExtrasTranslation($event, code, index)}}})]},proxy:true}],null,true)})],1)})}}],null,false,285822752)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }