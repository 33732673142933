import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',{staticClass:"grey--text text--darken-2"},[_vm._v(_vm._s(_vm.$t('Images')))]),_c('product-image-create',{attrs:{"value":_vm.value},on:{"created":_vm.onImageCreated},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [(_vm.canCreate && (_vm.canReadMedia || _vm.canCreateMedia))?_c(VBtn,_vm._g(_vm._b({attrs:{"depressed":"","small":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('Add image'))+" ")]):_vm._e()]}}])})],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.images,"hide-default-footer":"","item-key":"key","items-per-page":-1},scopedSlots:_vm._u([{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('image-zoom',{scopedSlots:_vm._u([{key:"thumbnail",fn:function(){return [_c('product-image-preview',{staticClass:"my-3 rounded-lg",attrs:{"value":item.url,"height":"40","width":"40"}})]},proxy:true},{key:"zoom",fn:function(){return [_c('product-image-preview',{attrs:{"value":item.url,"max-width":"300"}})]},proxy:true}],null,true)})]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('product-image-update',{attrs:{"value":item,"product":_vm.value},on:{"updated":_vm.onImageUpdate},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('button-action',_vm._g(_vm._b({attrs:{"icon-name":"edit","icon":"","label":_vm.$t('Edit')}},'button-action',attrs,false),on))]}}],null,true)})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [(_vm.value.id)?_c('product-action-delete-image',{attrs:{"icon":"","product-id":_vm.value.id,"value":item},on:{"success":function($event){return _vm.onImageDelete(item)},"error":_vm.onDeleteError}}):_c('delete-button',{attrs:{"suppress-confirmation":"","icon":true},on:{"click":function($event){return _vm.onImageDelete(item)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }