import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":"","depressed":"","color":"primary","loading":_vm.loading.updateAttributes}},'v-btn',Object.assign({}, _vm.$attrs, {attrs: attrs}),false),on),[_vm._v(" "+_vm._s(_vm.$t('Update attributes'))+" ")])]}}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"d-flex justify-space-between"},[_c('h3',[_vm._v(_vm._s(_vm.$t('Select attributes to update')))]),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.showDialog = false}}},[_c(VIcon,[_vm._v("close")])],1)],1),_c(VDivider),_c(VCardText,[_c('selectable-product-attributes',{attrs:{"items":_vm.allowedAttributes},model:{value:(_vm.selectedAttributes),callback:function ($$v) {_vm.selectedAttributes=$$v},expression:"selectedAttributes"}})],1),_c(VDivider),_c(VCardActions,{staticClass:"d-flex justify-end ma-2"},[(_vm.hasProductChanged)?_c('div',{staticClass:"d-flex flex-column flex-gap-16"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t( 'You have unsaved changes in your product. Without saving first, the updated variants will not include these changes. Would you like to save the product first and then update the variants?' ))+" ")]),_c('div',{staticClass:"d-flex justify-space-between"},[_c(VBtn,{attrs:{"color":"secondary","depressed":"","small":"","disabled":_vm.loading.updateAttributes},on:{"click":_vm.propagateAttributes}},[_vm._v(" "+_vm._s(_vm.$t('Update without changes'))+" ")]),_c(VBtn,{attrs:{"color":"primary","loading":_vm.loading.productUpdate,"depressed":"","small":""},on:{"click":_vm.updateProductFirst}},[_vm._v(" "+_vm._s(_vm.$t('Save changes and update'))+" ")])],1)]):_c(VBtn,{attrs:{"color":"primary","depressed":"","disabled":_vm.loading.updateAttributes},on:{"click":_vm.propagateAttributes}},[_vm._v(" "+_vm._s(_vm.$t('Update variants'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }