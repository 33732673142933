import { Money } from '@futureecom/futureecom-js'
import { recalculateGross, recalculateNet } from '@/libs/vatRecalcuations'
import type { PriceRecalculationContext } from '@/types/PriceRecalculationContext'

export const getUpdatedNet = (
  context: PriceRecalculationContext
): Record<'price' | 'sale_price', Money | null | undefined> => {
  const { vat, price, gross_price, gross_sale_price } = context

  return {
    price: gross_price && vat ? recalculateNet(gross_price, vat) : gross_price || price,
    sale_price: gross_sale_price && vat ? recalculateNet(gross_sale_price, vat) : gross_sale_price
  }
}

export const getUpdatedGross = (
  context: PriceRecalculationContext
): Record<'gross_price' | 'gross_sale_price', Money | null | undefined> => {
  const { vat, price, sale_price } = context

  return {
    gross_price: price && vat ? recalculateGross(price, vat) : price,
    gross_sale_price: sale_price && vat ? recalculateGross(sale_price, vat) : sale_price
  }
}
