import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","depressed":""},on:{"click":function($event){_vm.dialogVisible = true}}},'v-btn',_vm.$attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.$t('Export')))]),_c(VIcon,{staticClass:"ms-2",attrs:{"small":""}},[_vm._v("cloud_download")])],1)]}}]),model:{value:(_vm.dialogVisible),callback:function ($$v) {_vm.dialogVisible=$$v},expression:"dialogVisible"}},[_c(VCard,[_c(VCardTitle,{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.$t('Export products')))]),_c(VBtn,{attrs:{"icon":"","small":"","depressed":""},on:{"click":_vm.closeDialog}},[_c(VIcon,[_vm._v("close")])],1)],1),_c(VDivider),_c(VCardText,[_c('switch-field',{attrs:{"label":_vm.$t('Include products children in exported file')},model:{value:(_vm.withChildren),callback:function ($$v) {_vm.withChildren=$$v},expression:"withChildren"}})],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":"","loading":_vm.loading},on:{"click":_vm.exportProducts}},[_vm._v(" "+_vm._s(_vm.$t('Export'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }