import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasProductChanged)?_c(VDialog,{attrs:{"max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return undefined}}],null,false,2797824463),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.$t('Unsaved changes')))]),_c(VBtn,{attrs:{"icon":"","small":"","depressed":""},on:{"click":function($event){_vm.showDialog = false}}},[_c(VIcon,[_vm._v("close")])],1)],1),_c(VDivider),_c(VCardText,{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.$t( 'You have unsaved changes in your product. Without saving first, the newly generated variants will not include these changes.Would you like to save the product first and then generate the variants ? ' ))+" ")]),_c(VDivider),_c(VCardActions,{staticClass:"d-flex justify-space-between"},[_c(VBtn,{attrs:{"color":"secondary","text":"","small":"","disabled":_vm.loading.generateVariants},on:{"click":_vm.generateVariants}},[_vm._v(" "+_vm._s(_vm.$t('Generate without changes'))+" ")]),_c(VBtn,{attrs:{"color":"primary","text":"","loading":_vm.loading.productUpdate,"small":""},on:{"click":_vm.updateProductFirst}},[_vm._v(" "+_vm._s(_vm.$t('Save changes and generate'))+" ")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }