import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_vm._l((_vm.items),function(item){return [_c(VRow,{key:item.value,attrs:{"align":"center","justify":"space-between"}},[_c(VCol,{staticClass:"py-0"},[_c(VCheckbox,{attrs:{"indeterminate":item.children &&
            item.children.some(function (i) { return _vm.selectedAttributes.includes(i.value); }) &&
            !_vm.selectedAttributes.includes(item.value),"label":_vm.$t(item.text),"value":item.value,"hide-details":"auto"},on:{"click":function () { return item.children && _vm.selectNestedItem(item); }},model:{value:(_vm.selectedAttributes),callback:function ($$v) {_vm.selectedAttributes=$$v},expression:"selectedAttributes"}})],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"2"}},[_c(VRow,{attrs:{"justify":"end"}},[(item.children)?_c(VBtn,{staticClass:"mx-2 mt-4",attrs:{"icon":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.expand(item.value)}}},[_c(VIcon,[_vm._v("expand_more")])],1):_vm._e()],1)],1)],1),_c(VSlideYTransition,{key:("expanded-" + (item.value)),attrs:{"mode":"out-in"}},[(_vm.expandedAttributes.includes(item.value))?_c('div',{staticClass:"my-4"},_vm._l((item.children),function(child){return _c(VRow,{key:child.value,staticClass:"mx-4",attrs:{"align":"center","justify":"space-between"}},[_c(VCol,{staticClass:"py-0"},[_c(VCheckbox,{attrs:{"label":_vm.$t(child.text),"value":child.value,"hide-details":"auto"},on:{"click":function () { return item.children && _vm.selectNestedItem(item, child); }},model:{value:(_vm.selectedAttributes),callback:function ($$v) {_vm.selectedAttributes=$$v},expression:"selectedAttributes"}})],1)],1)}),1):_vm._e()])]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }