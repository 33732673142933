import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-gap-32"},[_c('expandable-content',{key:_vm.expandComponents,attrs:{"outlined":"","expand":_vm.expandComponents},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('selectable-resource-header',{staticClass:"mx-2",attrs:{"title":_vm.$t('Added components'),"items":_vm.components}})]},proxy:true},{key:"subtitle",fn:function(){return [_c('rule-validator',{staticClass:"mx-4",attrs:{"value":_vm.components,"rules":_vm.validator.rules.components}})]},proxy:true},{key:"content",fn:function(){return [_c(VDataTable,{attrs:{"headers":_vm.componentGrid.headers,"items":_vm.components,"item-key":"id"},scopedSlots:_vm._u([{key:"item.product.sku",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'product-update', params: { id: item.product_id } }}},[_vm._v(" "+_vm._s(item.product.sku)+" ")])]}},{key:"item.main",fn:function(ref){
var item = ref.item;
return [_c(VSwitch,{attrs:{"input-value":item.main},on:{"change":function($event){return _vm.onComponentUpdate(Object.assign({}, item, {main: $event, optional: false, include_by_default: false}))}}})]}},{key:"item.optional",fn:function(ref){
var item = ref.item;
return [_c(VSwitch,{attrs:{"input-value":item.optional,"disabled":item.main},on:{"change":function($event){return _vm.onComponentUpdate(Object.assign({}, item, {optional: $event}))}}})]}},{key:"item.include_by_default",fn:function(ref){
var item = ref.item;
return [_c(VSwitch,{attrs:{"input-value":item.include_by_default,"disabled":item.main},on:{"change":function($event){return _vm.onComponentUpdate(Object.assign({}, item, {include_by_default: $event}))}}})]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('product-component-update',{attrs:{"product":item.product,"value":item,"bundle":_vm.value},on:{"updated":_vm.onComponentUpdate},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('button-action',_vm._g(_vm._b({attrs:{"icon-name":"edit","icon":"","label":_vm.$t('Edit')}},'button-action',attrs,false),on))]}}],null,true)})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('button-action',{attrs:{"icon-name":"delete","icon":"","label":_vm.$t('Delete')},on:{"click":function($event){return _vm.onComponentDelete(item)}}})]}}])})]},proxy:true}])}),_c('selectable-products',{attrs:{"grid-options":_vm.productGrid,"request-options":{ filter: { classification: { neq: 'bundle' } } },"show-select":false,"filter-definitions":_vm.filterDefinitions},scopedSlots:_vm._u([{key:"item.sku",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-no-wrap",attrs:{"to":{ name: 'product-update', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.sku)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('product-component-create',{attrs:{"value":item,"bundle":_vm.value},on:{"created":function($event){return _vm.onComponentCreate($event, item)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":"","depressed":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('Add to bundle'))+" ")])]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }