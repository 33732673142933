import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!!_vm.value.id && _vm.showUpdateWarning)?_c(VAlert,{attrs:{"type":"warning","dismissible":""}},[_c('span',[_vm._v(_vm._s(_vm.$t('Update product in order to apply changes to price tiers')))])]):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',{staticClass:"grey--text text--darken-2"},[_vm._v(_vm._s(_vm.$t('Tiers')))]),_c('product-tier-dialog',{attrs:{"product":_vm.value},on:{"save":_vm.onTierCreate},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"depressed":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('Add new tier'))+" ")])]}}])})],1),_c(VDataTable,{staticClass:"v-data-table-shadowless",attrs:{"item-key":"index","items":_vm.tiers,"headers":_vm.tierHeaders},scopedSlots:_vm._u([{key:"item.price.amount",fn:function(ref){
var item = ref.item;
return [_c('money-preview',{attrs:{"value":item.price}})]}},{key:"item.gross_price.amount",fn:function(ref){
var item = ref.item;
return [_c('money-preview',{attrs:{"value":item.gross_price}})]}},{key:"item.customer_groups",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s((item.customer_groups || []).join(', ')))])]}},{key:"item.percentage",fn:function(ref){
var item = ref.item;
return [(item.type === 'percentage')?_c('span',[_vm._v(_vm._s(item.percentage)+"%")]):_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.$t('N/A')))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-gap-8"},[_c('product-tier-dialog',{attrs:{"value":item,"product":_vm.value},on:{"save":_vm.onTierUpdated},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('button-action',_vm._g({attrs:{"attrs":attrs,"icon-name":"edit","icon":"","label":_vm.$t('Edit')}},on))]}}],null,true)}),_c('delete-button',{attrs:{"icon":true,"suppress-confirmation":""},on:{"click":function($event){return _vm.onTierDeleted(item.index)}}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }