import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VList,[_c(VListGroup,{attrs:{"no-action":"","append-icon":"","color":"","disabled":!_vm.canCreate},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VListItemContent,{staticClass:"flex-grow-1",attrs:{"slot":"activator"},slot:"activator"},[_c(VListItemTitle,{staticClass:"d-flex justify-space-between align-center flex-grow-1"},[_vm._t("header"),(_vm.canCreate)?_c('div',[_c(VBtn,{attrs:{"color":"primary"}},[_c(VIcon,[_vm._v(_vm._s(_vm.open ? 'remove' : 'add'))])],1),_vm._t("after-icon")],2):_vm._e()],2)],1),_vm._t("content")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }