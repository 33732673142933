import { VImg } from 'vuetify/lib/components/VImg';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('force-mass-delete-dialog',{attrs:{"items":_vm.items,"loading":_vm.loading,"headers":_vm.headers},on:{"delete":_vm.deleteHandler},scopedSlots:_vm._u([{key:"item.image_url",fn:function(ref){
var item = ref.item;
return [_c(VImg,{staticClass:"my-3 rounded-lg",attrs:{"src":item.image_url,"height":"75","width":"75"}})]}},{key:"item.sku",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'product-update', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.sku)+" ")])]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(item.message))])]}}]),model:{value:(_vm.errors),callback:function ($$v) {_vm.errors=$$v},expression:"errors"}})}
var staticRenderFns = []

export { render, staticRenderFns }