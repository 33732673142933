import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center flex-gap-16"},[_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t('Will propagate selected Attributes from Parent product to selected Variations.'))+" ")]),_c('product-action-variants-propagation',{attrs:{"disabled":!_vm.showAttributesButton,"value":_vm.product,"items":_vm.selectedVariants}})],1),_c(VDataTable,{staticClass:"my-4",attrs:{"items":_vm.value,"headers":_vm.headers,"show-select":""},scopedSlots:_vm._u([{key:"item.image_url",fn:function(ref){
var item = ref.item;
return [_c('image-zoom',{scopedSlots:_vm._u([{key:"thumbnail",fn:function(){return [_c('product-image-preview',{staticClass:"my-3 rounded-lg",attrs:{"value":item.image_url,"height":"40","width":"40"}})]},proxy:true},{key:"zoom",fn:function(){return [_c('product-image-preview',{attrs:{"value":item.image_url,"max-width":"300"}})]},proxy:true}],null,true)})]}},{key:"item.sku",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'product-update', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.sku)+" ")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('money-preview',{attrs:{"value":item.price}})]}},{key:"item.sale_price",fn:function(ref){
var item = ref.item;
return [_c('money-preview',{attrs:{"value":item.sale_price}})]}}]),model:{value:(_vm.selectedVariants),callback:function ($$v) {_vm.selectedVariants=$$v},expression:"selectedVariants"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }