import Big from 'big.js'
import type { Money } from '@futureecom/futureecom-js'

export const calculatePricePercentage = (
  product: Partial<Record<'price' | 'gross_price', null | Money>>,
  percentage: number
) => {
  const recalculate = (value: Money, percentage: number) => {
    return {
      amount: new Big(value.amount)
        .mul(100 - percentage)
        .div(100)
        .toFixed(0),
      currency: value.currency
    }
  }

  return {
    price: product.price ? recalculate(product.price, percentage) : null,
    gross_price: product.gross_price ? recalculate(product.gross_price, percentage) : null
  }
}
