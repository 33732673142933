import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSlideYReverseTransition } from 'vuetify/lib/components/transitions';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAlert,{attrs:{"type":"info","dismissible":""},scopedSlots:_vm._u([{key:"close",fn:function(){return [_c(VBtn,{staticClass:"align-self-start",attrs:{"depressed":"","icon":"","small":""},on:{"click":function($event){_vm.visible = !_vm.visible}}},[_c(VIcon,[_vm._v(_vm._s(_vm.visible ? 'expand_less' : 'expand_more'))])],1)]},proxy:true}])},[_c('span',[_vm._v(_vm._s(_vm.$t('This product is used with bundle(s)')))]),_c(VSlideYTransition,[(_vm.bundlesLoading)?_c(VProgressCircular,{staticClass:"mx-4",attrs:{"size":"24","color":"white","indeterminate":""}}):_vm._e()],1),(_vm.visible)?_c('div',[_c(VSlideYReverseTransition,{attrs:{"group":""}},_vm._l((_vm.bundles),function(product,index){return _c('div',{key:product.id,staticClass:"mt-2"},[(index > 0)?_c(VDivider,{staticClass:"my-2"}):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(product.name)+" ("+_vm._s(product.sku)+")")]),_c(VBtn,{attrs:{"depressed":"","icon":"","small":"","fab":"","to":{ name: 'product-update', params: { id: product.id } }}},[_c(VIcon,[_vm._v("chevron_right")])],1)],1)],1)}),0),_c(VDivider,{staticClass:"my-2"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('To delete this product, remove or replace the component from bundle or delete bundle first'))+" ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }